<template>
    <div class="wrap">
        <div class="nav_bar">
            <van-icon name="arrow-left" size="20" style="position: absolute;left: 18px;top: 15px;"
                @click="onClickLeft" />
            <span style="font-size: 20px;color:#fff; font-weight: bold;">昨日当天门诊量</span>
        </div>
        <!-- <div class="time_box">
            <van-cell title="选择日期区间" :value="date" @click="showPicker = true" />
            <van-calendar v-model="showPicker" type="range" @confirm="onConfirm" />
        </div> -->
         <van-empty image="search" description="暂无数据" v-if="Object.keys(dataList).length === 0"/>
        <div id="main" style=" height: 300px;background-color: #fff;margin: 40px 20px 0px 20px;" v-else></div>
        <!-- <div id="main" ></div> -->
    </div>
</template>
<script>
import dateUtil from '@/api/dateUtil.js';
import { getyesterdayoutpatient } from "@/api/online";
import * as echarts from 'echarts';
export default {
    data() {
        return {
            date: '',
            dataList: {},
            showPicker: false,
            tenantId: localStorage.getItem('tenantId'),
            startTime: '',
            endTime: '',
        }
    },
    mounted() {
        this.getdata()
    },
    methods: {
        getdata() {
            getyesterdayoutpatient(
                this.tenantId,
                this.startTime,
                this.endTime,
            ).then((res) => {
                if (res.data.code == "200") {
                    this.dataList = res.data.data
                    this.$nextTick(() => {
                        this.getChart()
                    })
                }
            });
        },
        getChart() {
            var chartDom = document.getElementById('main');
            var myChart = echarts.init(chartDom);
            var option;
            let Xarr = ['住院','门诊','手术']
            let mzarr = []
            let jzarr = []
            mzarr.push(this.dataList.numInhospYestoday,this.dataList.numOutpYestoday,this.dataList.opertionYestoday)
            jzarr.push(this.dataList.numInhospToday,this.dataList.numOutpToday,this.dataList.opertionToday)
            option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: Xarr
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '昨日',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: mzarr
                    },
                    {
                        name: '今日',
                        type: 'bar',
                        data: jzarr,
                        emphasis: {
                            focus: 'series'
                        },
                    },
                ]
            };
            option && myChart.setOption(option);

        },
        onConfirm(date) {
            const [start, end] = date;
            this.showPicker = false;
            this.date = `${dateUtil.formatDate1(start)}-${dateUtil.formatDate1(end)}`;
            this.startTime = dateUtil.formatDate3(start)
            this.endTime = dateUtil.formatDate3(end)
            this.getdata()
        },
        onClickLeft() {
            this.$router.back()
        },
        onClickInfo(router) {
            this.$router.push({ path: router })
        },
    }
}
</script>
<style lang="less" scoped>
.wrap {
    background-color: #F6F6F6;
    // padding-top: 20px;
    height: 100vh;
    
    //  .box {
    //         position: relative;
    //         width: 100%;
    //         height: 200px;
    //         background-color: green;
    //     }
        // .nav_bar:after {
        //     position: absolute;
        //     left: 0;
        //     right: 0;
        //     bottom: -100px;
        //     z-index: -1;
        //     content: ' ';
        //     height: 200px;
        //     width: 100%;
        //     border-radius: 0 0 50% 50%;
        //     background-color: green;
        // }
    .nav_bar {
        text-align: center;
        color: #fff;
        background-color: #1578F9;
        padding: 10px 0px 10px 0px;
        // position: relative; 
    }

    .time_box {
        width: 90%;
        margin: -20px auto;
    }
}

/deep/.van-cell {
    border-radius: 10px !important;

}

.van-icon-arrow {
    color: #999;
}
</style>